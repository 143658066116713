import React from 'react';


const HomeScreen = () => {
  return (
    <>
      <section className="home-screen-section" id='homeScreen'>
        <h1>Eugen Borth <br/> Straßenreinigung</h1>
      </section>
    </>
  );
}

export default HomeScreen;
